<template>
  <article id="wrap">

    <header>
      <img src="@/assets/img/tutorial/sutekinasekai_tutorial_1.png" style="max-height: 50vh">
    </header>


    <section class="contents">

      <div class="contentInner">
        <div class="bold ta_c mb_10">Not Found.</div>
        <div class="mb_20">ページがみつかりませんでした。URLが間違っているか、期限が切れているか、もしかしたら権限が必要なのかもしれません。</div>

      </div><!--.contentInner-->

    </section><!--.contents-->

  </article>
</template>

<script>
export default {
  name: 'NotFound',
  components: {
  }
}
</script>
